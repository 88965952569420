<template>
  <el-card shadow="always">
    <iframe id="frame" src="https://cdalbejkglyxgs.qiyukf.com/login?reqUrl=https://cdalbejkglyxgs.qiyukf.com/login"></iframe>
  </el-card>
</template>

<script>
export default {
  // mounted() {
  //   ;(function (w, d, n, a, j) {
  //     w[n] =
  //       w[n] ||
  //       function () {
  //         ;(w[n].a = w[n].a || []).push(arguments)
  //       }
  //     j = d.createElement('script')
  //     j.async = true
  //     j.src = 'https://cdalbejkglyxgs.qiyukf.com/login?reqUrl=https://cdalbejkglyxgs.qiyukf.com/login'
  //     d.body.appendChild(j)
  //   })(window, document, 'ysf')
  //   window.isSdkReady = false
  //   window.fn = []
  //   ysf('onready', function () {
  //     window.isSdkReady = true
  //     if (window.fn[0]) {
  //       window.fn[0]()
  //     }
  //   })
  //   function openUrl(data, type, callBack) {
  //     let isOk = false
  //     ysf('config', {
  //       uid: data.playerId,
  //       data: JSON.stringify([
  //         // {'key': 'real_name', 'value': data.playerName},
  //         // {'key': 'player_id', 'label': "玩家ID", 'value': data.playerId},
  //       ]),
  //       staffid: data.qiyuKefuId, // 客服id
  //       success: function () {
  //         // 胜利回调
  //         if (isOk) {
  //           return
  //         }
  //         let url = ysf('url')
  //         callBack(url)
  //         isOk = true
  //       },
  //       error: function (e) {
  //         // 谬误回调
  //         if (isOk) {
  //           return
  //         }
  //         isOk = true
  //         callBack('网络连接失败')
  //       },
  //     })
  //   }
  // },
}
</script>

<style scoped>
html,
body,
main {
  height: 100%;
}
.el-card,
.el-card__body {
  height: 100% !important;
}
.j-loginbox .g-mn {
  height: 100% !important;
}
iframe {
  width: 100%;
  height: 100%;
}
</style>
